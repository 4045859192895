<template>
  <div>
    <b-col>
      <div class="card card-custom card-stretch mt-4">
        <div class="card-header py-3">
          <div class="card-title align-items-start flex-column"></div>
          <div class="card-toolbar">
            <button type="submit" class="btn btn-success mr-2" ref="kt_save_changes" @click="saveStudent">
              <span class="navi-icon">
                Pindahkan siswa ke kelas
                <i class="flaticon2-right-arrow"></i>
              </span>
            </button>
          </div>
        </div>
        <div class="card-body pt-4">
          <div class="alert alert-custom alert-light-info fade show mb-10" role="alert">
            <div class="alert-icon">
              <span class="svg-icon svg-icon-3x svg-icon-info">
                <!--begin::Svg Icon | path:assets/media/svg/icons/Code/Info-circle.svg-->
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                  height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24" />
                    <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                    <rect fill="#000000" x="11" y="10" width="2" height="7" rx="1" />
                    <rect fill="#000000" x="11" y="7" width="2" height="2" rx="1" />
                  </g>
                </svg>
                <!--end::Svg Icon-->
              </span>
              <div class="alert-text font-weight-bold">
                Tabel siswa , centang pada baris untuk menambahkan siswa.
              </div>
            </div>
          </div>

          <b-row class="justify-content-between">
            <b-col cols="auto">
              <b-form-group>
                <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="auto">
              <b-form-group label="Filter" label-for="filter-input" label-cols-sm="3" label-align-sm="right"
                label-size="sm" class="mb-0">
                <b-input-group size="sm">
                  <b-form-input id="filter-input" v-model="filter" type="search"
                    placeholder="Type to Search"></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-table :filter="filter" @filtered="onFiltered" :items="items" :fields="fields" :busy="isLoading" show-empty
            class="
              table
              table-head-custom
              table-vertical-center
              table-head-bg
              table-borderless
              card-table
            " responsive>
            <template #empty>
              <h6 class="text-center">Tidak ada siswa ditemukan</h6>
            </template>
            <template #emptyfiltered>
              <h6 class="text-center">Tidak ada siswa ditemukan</h6>
            </template>
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #head(select)>
              <span>Pilih</span>
            </template>
            <template #cell(index)="row">
              {{ perPage * (currentPage - 1) + (row.index + 1) }}
            </template>
            <template #cell(select)="data">
              <label class="checkbox checkbox-lg checkbox-single">
                <input type="checkbox" :checked="isExistList(data)" @change="check(data)" />
                <span></span>
              </label>
            </template>
          </b-table>
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right"
            class="my-0"></b-pagination>
        </div>
      </div>
    </b-col>
  </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import {
  STORE_CLASS,
  ASSIGN_STUDENT_CLASS,
  GET_STUDENT_UNASSIGN,
} from "@/core/services/store/kelas.module";

import Swal from "sweetalert2";

import { GET_USER_LIST, ROLE_STUDENT } from "@/core/services/store/user.module";

export default {
  name: "SiswaTidakTerdaftar",
  props: {
    callback: Function,
  },
  data() {
    return {
      currentKelasForm: {
        body: {},
      },
      isLoading: false,
      isCreate: true,
      editorOption: {
        // Some Quill options...
      },
      currentTeacherForm: {
        class_id: {},
        students_id: [],
      },
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      filter: null,
      items: [],
      fields: [
        {
          key: "select",
          sortable: false,
        },
        {
          key: "index",
          label: "No",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "full_name",
          label: "Nama Siswa",
          sortable: true,
        },
      ],
    };
  },
  watch: {
    perPage: function () {
      this.getData();
    },
    currentPage: function () {
      this.getData();
    },
    filter: function () {
      this.getData();
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    submitFormKelas() {
      this.$refs.formKelas.validate().then((success) => {
        console.log(success);
        if (success) {
          const _data = JSON.parse(JSON.stringify(this.currentKelasForm));
          this.$store
            .dispatch(STORE_CLASS, _data)
            .then((response) => {
              Swal.fire({
                title: response.message,
                text: `Berhasil Buat Kelas`,
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: false,
              });
              this.isLoading = false;
            })
            .catch(() => {
              this.isLoading = false;
              this.$bvToast.toast(this.errors, {
                title: `Gagal Membuat Siswa`,
                variant: "danger",
                solid: true,
              });
            });
        }
      });
    },
    isExistList: function (data) {
      const item = data.item._id;

      const isExist =
        this.currentTeacherForm.students_id.filter((elem) => elem == item)
          .length > 0;
      return isExist;
    },
    check: function (data) {
      const item = data.item._id;

      const isExist =
        this.currentTeacherForm.students_id.filter((elem) => elem == item)
          .length > 0;

      if (isExist) {
        this.currentTeacherForm.students_id =
          this.currentTeacherForm.students_id.filter((elem) => {
            elem != item;
          });
      } else {
        this.currentTeacherForm.students_id.push(item);
      }
    },
    removeDuplicate(kelasArr) {
      const arr = kelasArr.map((item) => {
        return item.kelas.nama_kelas;
      });
      return arr.filter((item, pos, self) => {
        return self.indexOf(item) == pos;
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onFilteredSiswa(filteredItems, item) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      item.totalRows = filteredItems.length;
      item.currentPage = 1;
    },
    getData() {
      this.isLoading = true;
      const params = new URLSearchParams();
      params.append("class_id", this.idKelas);
      params.append("limit", this.perPage);
      params.append("page", this.currentPage);
      params.append("name", this.filter);

      this.$store
        .dispatch(GET_STUDENT_UNASSIGN, params.toString())
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.totalRows = response.data.total;
          this.items = response.data.data;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Siswa`,
            variant: "danger",
            solid: true,
          });
        });
    },
    onReset() { },
    saveStudent() {
      if (this.currentTeacherForm.students_id.length > 0) {
        this.currentTeacherForm.class_id = this.idKelas;
        const _data = JSON.parse(JSON.stringify(this.currentTeacherForm));
        this.$store
          .dispatch(ASSIGN_STUDENT_CLASS, _data)
          .then((response) => {
            Swal.fire({
              title: response.message,
              text: `Berhasil memasukkan Siswa`,
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });

            this.currentTeacherForm = {
              class_id: {},
              students_id: [],
            }

            this.callback();
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
            this.$bvToast.toast(this.errors, {
              title: `Gagal memasukan siswa`,
              variant: "danger",
              solid: true,
            });
          });
      }
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.classModule.errors,
    }),
    ...mapGetters(["currentUnassignStudent"]),
    idKelas() {
      return this.$route.params.idKelas;
    },
  },
};
</script>
