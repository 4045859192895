<template>
  <div>
    <a @click="$router.back()" href="#" class="
        text-dark-75
        font-weight-bolder
        text-hover-primary
        mb-1
        font-size-lg
        d-flex
        flex-row
        align-items-center
      ">
      <i class="mdi mdi-chevron-left"></i>
      Kembali
    </a>
    <b-row v-if="role == 'teacher'">
      <b-col>
        <GuruTidakTerdaftar ref="gurutidakterdaftar" v-bind:callback="refreshTeacher"></GuruTidakTerdaftar>
      </b-col>
      <b-col>
        <GuruTerdaftar ref="guruterdaftar" v-bind:callback="refreshTeacher"></GuruTerdaftar>
      </b-col>
    </b-row>
    <b-row v-if="role == 'student'">
      <b-col>
        <SiswaTidakTerdaftar ref="siswatidakterdaftar" v-bind:callback="refresh"></SiswaTidakTerdaftar>
      </b-col>
      <b-col>
        <SiswaTerdaftar ref="siswaterdaftar" v-bind:callback="refresh"></SiswaTerdaftar>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import { STORE_CLASS, ASSIGN_CLASS } from "@/core/services/store/kelas.module";

import Swal from "sweetalert2";

import { GET_USER_LIST, ROLE_TEACHER } from "@/core/services/store/user.module";

import GuruTerdaftar from "@/view/pages/kelas/guru/guruTerdaftar.vue";
import GuruTidakTerdaftar from "@/view/pages/kelas/guru/guruTidakTerdaftar.vue";

import SiswaTerdaftar from "@/view/pages/kelas/siswa/siswaTerdaftar.vue";
import SiswaTidakTerdaftar from "@/view/pages/kelas/siswa/siswaTidakTerdaftar.vue";

export default {
  name: "KelasForm",
  components: {
    GuruTerdaftar,
    GuruTidakTerdaftar,
    SiswaTerdaftar,
    SiswaTidakTerdaftar,
  },
  data() {
    return {
      currentKelasForm: {
        body: {},
      },
      isLoading: false,
      isCreate: true,
      editorOption: {
        // Some Quill options...
      },
      currentTeacherForm: {
        class_id: {},
        teachers_id: [],
      },
    };
  },
  created() {

  },
  mounted() {
    if (this.role == 'student') {
      Promise.all([
        this.$refs.siswatidakterdaftar.getData(),
        this.$refs.siswaterdaftar.getData()
      ])
    } else {
      Promise.all([
        this.$refs.gurutidakterdaftar.getData(),
        this.$refs.guruterdaftar.getData()
      ])
    }
  },
  methods: {
    refresh: function () {
      this.$refs.siswatidakterdaftar.getData();
      this.$refs.siswaterdaftar.getData();
    },

    refreshTeacher: function () {
      this.$refs.gurutidakterdaftar.getData()
      this.$refs.guruterdaftar.getData()
    },
    submitFormKelas() {
      this.$refs.formKelas.validate().then((success) => {
        console.log(success);
        if (success) {
          const _data = JSON.parse(JSON.stringify(this.currentKelasForm));
          this.$store
            .dispatch(STORE_CLASS, _data)
            .then((response) => {
              Swal.fire({
                title: response.message,
                text: `Berhasil Buat Kelas`,
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: false,
              });
              this.$router.push(`/kelas`);
              this.isLoading = false;
            })
            .catch(() => {
              this.isLoading = false;
              this.$bvToast.toast(this.errors, {
                title: `Gagal Membuat Kelas`,
                variant: "danger",
                solid: true,
              });
            });
        }
      });
    },
    check: function (data) {
      const item = data.item.teacher._id;

      const isExist =
        this.currentTeacherForm.teachers_id.filter((elem) => elem == item)
          .length > 0;

      if (isExist) {
        this.currentTeacherForm.teachers_id =
          this.currentTeacherForm.teachers_id.filter((elem) => {
            elem != item;
          });
      } else {
        this.currentTeacherForm.teachers_id.push(item);
      }
    },
    removeDuplicate(kelasArr) {
      const arr = kelasArr.map((item) => {
        return item.kelas.nama_kelas;
      });
      return arr.filter((item, pos, self) => {
        return self.indexOf(item) == pos;
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onFilteredSiswa(filteredItems, item) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      item.totalRows = filteredItems.length;
      item.currentPage = 1;
    },
    getData() {
      this.isLoading = true;
      const params = new URLSearchParams();
      params.append("where['role']", ROLE_TEACHER);
      params.append("limit", this.perPage);
      params.append("page", this.currentPage);
      this.$store
        .dispatch(GET_USER_LIST, params.toString())
        .then(() => {
          this.isLoading = false;
          this.totalRows = this.currentUserList.total;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Guru`,
            variant: "danger",
            solid: true,
          });
        });
    },
    onReset() { },
    saveTeacher() {
      if (this.currentTeacherForm.teachers_id.length > 0) {
        this.currentTeacherForm.class_id = this.idKelas;
        const _data = JSON.parse(JSON.stringify(this.currentTeacherForm));
        this.$store
          .dispatch(ASSIGN_CLASS, _data)
          .then((response) => {
            Swal.fire({
              title: response.message,
              text: `Berhasil memasukkan guru`,
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
            this.$router.push(`/kelas`);
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
            this.$bvToast.toast(this.errors, {
              title: `Gagal memasukan guru`,
              variant: "danger",
              solid: true,
            });
          });
      }
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.classModule.errors,
    }),
    ...mapGetters(["currentUserList"]),
    idKelas() {
      return this.$route.params.idKelas;
    },
    role() {
      return this.$route.params.role;
    },
  },
};
</script>
