<template>
  <div>
    <b-col>
      <div class="card card-custom card-stretch mt-4">
        <div class="card-header py-3">
          <div class="card-title align-items-start flex-column">
            <button type="submit" class="btn btn-danger mr-2" ref="kt_save_changes" @click="saveStudent">
              <span class="navi-icon">
                <i class="flaticon2-left-arrow"></i>
                Pindahkan Siswa dari kelas
              </span>
            </button>
          </div>
        </div>
        <div class="card-body pt-4">
          <div class="alert alert-custom alert-light-info fade show mb-10" role="alert">
            <div class="alert-icon">
              <span class="svg-icon svg-icon-3x svg-icon-info">
                <!--begin::Svg Icon | path:assets/media/svg/icons/Code/Info-circle.svg-->
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                  height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24" />
                    <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                    <rect fill="#000000" x="11" y="10" width="2" height="7" rx="1" />
                    <rect fill="#000000" x="11" y="7" width="2" height="2" rx="1" />
                  </g>
                </svg>
                <!--end::Svg Icon-->
              </span>
              <div class="alert-text font-weight-bold">
                Tabel siswa, centang pada baris untuk mempindahkan siswa .
              </div>
            </div>
          </div>

          <b-row class="justify-content-between">
            <b-col cols="auto">
              <b-form-group>
                <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="auto">
              <b-form-group label="Filter" label-for="filter-input" label-cols-sm="3" label-align-sm="right"
                label-size="sm" class="mb-0">
                <b-input-group size="sm">
                  <b-form-input id="filter-input" v-model="filter" type="search"
                    placeholder="Type to Search"></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-table :filter="filter" @filtered="onFiltered" :items="listItem" :fields="fields" :busy="isLoading"
            show-empty class="
              table
              table-head-custom
              table-vertical-center
              table-head-bg
              table-borderless
              card-table
            " responsive>
            <template #empty>
              <h6 class="text-center">Tidak ada Siswa ditemukan</h6>
            </template>
            <template #emptyfiltered>
              <h6 class="text-center">Tidak ada Siswa ditemukan</h6>
            </template>
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>

            <template #cell(select)="data">
              <label class="checkbox checkbox-lg checkbox-single">
                <input type="checkbox" @change="check(data)" />
                <span></span>
              </label>
            </template>
            <template #head(select)>
              <span>Pilih</span>
            </template>

            <template #cell(index)="row">
              {{ perPage * (currentPage - 1) + (row.index + 1) }}
            </template>

            <template v-slot:cell(selected)="row">
              <b-form-group>
                <b-form-checkbox :value="row"></b-form-checkbox>
              </b-form-group>
            </template>
            <template #cell(action)>
              <b-dropdown variant="light"
                toggle-class="custom-v-dropdown btn btn-light btn-hover-light-primary btn-icon" no-caret right no-flip>
                <template v-slot:button-content>
                  <i class="ki ki-bold-more-hor"></i>
                </template>

                <!--end::Navigation-->
              </b-dropdown>
            </template>
          </b-table>
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right"
            class="my-0"></b-pagination>
        </div>
      </div>
    </b-col>
  </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import {
  UNASSIGN_STUDENT,
  GET_LIST_STUDENT_CLASS,
} from "@/core/services/store/kelas.module";

import Swal from "sweetalert2";

export default {
  name: "SiswaTerdaftar",
  props: {
    callback: Function,
  },
  data() {
    return {
      currentKelasForm: {
        body: {},
      },
      isLoading: false,
      isCreate: true,
      editorOption: {
        // Some Quill options...
      },
      listItem: [],
      currentTeacherForm: {
        class_id: {},
        students_id: [],
      },
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      filter: null,
      fields: [
        {
          key: "select",
          sortable: false,
        },
        {
          key: "index",
          label: "No",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "full_name",
          label: "Nama Siswa",
          sortable: true,
        },
      ],
    };
  },
  watch: {
    perPage: function () {
      this.getData();
    },
    currentPage: function () {
      this.getData();
    },
    filter: function () {
      this.getData();
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    onChangeAll() {
      this.bankSoal.forEach((item, index) => {
        // this.$set(this.bankSoal[index], "checked", !this.selectAll);
      });
    },
    onChangeRow(e) {
      // if (!e.target.checked) {
      //   this.selectAll = false;
      // } else {
      //   var allSelected = this.bankSoal.every((val) => {
      //     return val.checked;
      //   });
      //   if (allSelected) {
      //     this.selectAll = true;
      //   }
      // }
    },
    check: function (data) {
      const item = data.item._id;

      const isExist =
        this.currentTeacherForm.students_id.filter((elem) => elem == item)
          .length > 0;

      if (isExist) {
        this.currentTeacherForm.students_id =
          this.currentTeacherForm.students_id.filter((elem) => {
            elem != item;
          });
      } else {
        this.currentTeacherForm.students_id.push(item);
      }
    },
    removeDuplicate(kelasArr) {
      const arr = kelasArr.map((item) => {
        return item.kelas.nama_kelas;
      });
      return arr.filter((item, pos, self) => {
        return self.indexOf(item) == pos;
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onFilteredSiswa(filteredItems, item) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      item.totalRows = filteredItems.length;
      item.currentPage = 1;
    },
    getData() {
      this.isLoading = true;

      const params = new URLSearchParams();
      params.append("limit", this.perPage);
      params.append("page", this.currentPage);
      params.append("full_name", this.filter);
      params.append("class_id", this.idKelas);

      this.$store
        .dispatch(GET_LIST_STUDENT_CLASS, params.toString())
        .then((response) => {
          this.isLoading = false;
          this.totalRows = response.data.total;
          this.listItem = response.data.data;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Siswa`,
            variant: "danger",
            solid: true,
          });
        });
    },
    onReset() { },
    saveStudent() {
      if (this.currentTeacherForm.students_id.length > 0) {
        this.currentTeacherForm.class_id = this.idKelas;
        const _data = JSON.parse(JSON.stringify(this.currentTeacherForm));
        this.$store
          .dispatch(UNASSIGN_STUDENT, _data)
          .then((response) => {
            Swal.fire({
              title: response.message,
              text: `Berhasil memindahkan Siswa `,
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
            this.isLoading = false;
            this.currentTeacherForm = {
              class_id: {},
              students_id: [],
            }
            this.callback();
          })
          .catch(() => {
            this.isLoading = false;
            this.$bvToast.toast(this.errors, {
              title: `Gagal memindahkan siswa`,
              variant: "danger",
              solid: true,
            });
          });
      }
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.ujian.errors,
    }),
    ...mapGetters(["currentClassDetail", "currentStudentList"]),
    idKelas() {
      return this.$route.params.idKelas;
    },
  },
};
</script>
